<template>
    <Page>
        <h2>
            <Icon v="user" />
            <T>user.headerLong</T>
        </h2>

        <Account v-if="$user()" />
        <MfaValidation v-else-if="preToken" />
        <Login v-else />

        <!-- <Separator icon="users"/> -->
        <AccountSwitch class="my-5" helper :minimum-count="$user() ? 0 : 1" />
    </Page>
</template>

<script lang="ts">
import { useNuxtApp } from 'nuxt/app';
import { defineComponent } from 'vue';

import useSimpleHead from '../composables/useSimpleHead.ts';
import { useMainStore } from '../store/index.ts';

export default defineComponent({
    setup() {
        definePageMeta({
            translatedPaths: (config) => translatedPathByConfigModule(config.user),
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('user.headerLong'),
        }, translator);
        return {
            store: useMainStore(),
        };
    },
    computed: {
        preToken(): string | null {
            return this.store.preToken;
        },
    },
});
</script>
