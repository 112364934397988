<template>
    <Page>
        <NounsNav />

        <h2>
            <Icon v="book" />
            <T>nouns.headerLonger</T>
        </h2>

        <section>
            <T>nouns.intro</T>

            <Share :title="$t('nouns.headerLong')" />
        </section>

        <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />

        <NounsExtra>
            <details v-if="config.nouns.collapsable" ref="dictionarywrapper" class="border mb-3">
                <summary class="bg-light p-3" @click="collapsabledictionary?.loadNouns()">
                    <h4 class="h5 d-inline">
                        <Icon v="book" />
                        <T>nouns.dictionary</T>
                    </h4>
                </summary>
                <div class="border-top">
                    <Dictionary ref="collapsabledictionary" />
                </div>
            </details>
            <Dictionary v-else load />
        </NounsExtra>
    </Page>
</template>

<script lang="ts">
import { useNuxtApp } from 'nuxt/app';
import { defineComponent } from 'vue';

import type Dictionary from '~/components/Dictionary.vue';
import useConfig from '~/composables/useConfig.ts';
import useSimpleHead from '~/composables/useSimpleHead.ts';
import NounsExtra from '~/data/nouns/NounsExtra.vue';

export default defineComponent({
    components: { NounsNav: useLocaleComponent('nouns', 'NounsNav'), NounsExtra },
    setup() {
        definePageMeta({
            translatedPaths: (config) => translatedPathByConfigModule(config.nouns),
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('nouns.headerLonger'),
            description: translator.translate('nouns.description'),
        }, translator);

        const filter = useFilterWithCategory();
        const dictionarywrapper = useTemplateRef<HTMLDetailsElement>('dictionarywrapper');
        const collapsabledictionary = useTemplateRef<InstanceType<typeof Dictionary>>('collapsabledictionary');
        watchEffect(() => {
            if (filter.value.text && dictionarywrapper.value) {
                dictionarywrapper.value.open = true;
                collapsabledictionary.value?.loadNouns();
            }
        });

        return {
            config: useConfig(),
            filter,
            collapsabledictionary,
        };
    },
});
</script>
