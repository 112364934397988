import localeInfos from '../locale/locales.ts';
import type { LocaleDescription } from '../locale/locales.ts';

export default (current: string | null, includeUnpublished = false): Record<string, LocaleDescription> => {
    const d: Record<string, LocaleDescription> = {};
    for (const locale of localeInfos) {
        if (locale.published || current === locale.code || includeUnpublished) {
            d[locale.code] = locale;
        }
    }

    // hoist current to top, then published, then unpublished
    const sortedLocales: Record<string, LocaleDescription> = {};
    if (current !== null && d[current] !== undefined) {
        sortedLocales[current] = d[current];
    }
    for (const [code, localeConfig] of Object.entries(d)) {
        if (code !== current && localeConfig.published) {
            sortedLocales[code] = localeConfig;
        }
    }
    for (const [code, localeConfig] of Object.entries(d)) {
        if (code !== current && !localeConfig.published) {
            sortedLocales[code] = localeConfig;
        }
    }

    return sortedLocales;
};
