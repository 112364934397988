import type { RouterOptions } from '@nuxt/schema';

const routerOptions: RouterOptions = {
    routes: (routes) => {
        if (process.env.TEST) {
            // workaround as there is no way to retrieve the original page routes
            // which get overridden in the nuxt test environment by this router options
            global.originalRoutes = routes;
        }
        const config = useConfig();
        return routes.flatMap((route) => {
            if (route.meta?.translatedPaths) {
                const translatedPaths = route.meta.translatedPaths(config);
                if (translatedPaths.length === 0) {
                    return [];
                }
                if (Array.isArray(translatedPaths)) {
                    return [{ ...route, path: translatedPaths[0], alias: translatedPaths.slice(1) }];
                }
                return Object.entries(translatedPaths).map(([name, translatedRoute]) => {
                    return {
                        ...route,
                        name,
                        path: translatedRoute.paths[0],
                        alias: translatedRoute.paths.slice(1),
                        meta: translatedRoute.meta,
                    };
                });
            }
            return [route];
        });
    },
};
export default routerOptions;
