<template>
    <Page>
        <NounsNav />

        <h2>
            <Icon v="book" />
            <T>nouns.headerLonger</T>
        </h2>

        <section>
            <T>nouns.intro</T>

            <Share :title="$t('nouns.headerLong')" />
        </section>

        <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />

        <FilterBar v-if="config.nouns.templates?.filter" v-model="filter" />

        <NounTemplatesTable :filter="filter" />
    </Page>
</template>

<script lang="ts">
import { useNuxtApp } from 'nuxt/app';
import { defineComponent } from 'vue';

import useConfig from '~/composables/useConfig.ts';
import useSimpleHead from '~/composables/useSimpleHead.ts';

interface Refs {
    filter: HTMLInputElement | undefined;
}

export default defineComponent({
    setup() {
        definePageMeta({
            translatedPaths: (config) => {
                if (!config.nouns.enabled || !config.nouns.templates.enabled || !config.nouns.templates.route) {
                    return [];
                }
                return [`/${encodeURIComponent(config.nouns.templates.route)}`];
            },
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('nouns.headerLonger'),
            description: translator.translate('nouns.description'),
        }, translator);

        return {
            config: useConfig(),
        };
    },
    data() {
        return {
            filter: '',
        };
    },
    computed: {
        $tRefs(): Refs {
            return this.$refs as unknown as Refs;
        },
    },
});
</script>
