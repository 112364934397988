<template>
    <Page>
        <LinksNav />

        <h2>
            <Icon v="globe-americas" />
            <T>english.headerLonger</T>
        </h2>

        <div>
            <T>english.intro</T>
        </div>

        <template v-if="config.english.links">
            <Separator icon="link" />
            <ul class="list-unstyled">
                <LinkEntry v-for="link in config.english.links" :key="link.url" :link="link" />
            </ul>
        </template>

        <Separator icon="list" />

        <section v-for="pronounGroup in config.english.pronounGroups">
            <h3>
                {{ pronounGroup.name }}
            </h3>
            <p v-for="p in pronounGroup.description">
                <LinkedText :text="p" />
            </p>
            <EnglishTable v-if="pronounGroup.table" :t="pronounGroup.table" />
        </section>
    </Page>
</template>

<script lang="ts">
import { useNuxtApp } from 'nuxt/app';
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import useSimpleHead from '../composables/useSimpleHead.ts';
import EnglishTable from '../data/pronouns/EnglishTable.vue';

export default defineComponent({
    components: { EnglishTable },
    setup() {
        definePageMeta({
            translatedPaths: (config) => translatedPathByConfigModule(config.english),
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('english.headerLonger'),
            description: translator.translate('english.description'),
        }, translator);
        return {
            config: useConfig(),
        };
    },
});
</script>
