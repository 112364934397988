<template>
    <Page>
        <LinksNav />

        <AcademicLinks />

        <Separator icon="heart" />
        <Support />
        <section>
            <Share :title="$t('links.academic.header')" />
        </section>
    </Page>
</template>

<script lang="ts">
import { useNuxtApp } from 'nuxt/app';
import { defineComponent } from 'vue';

import useSimpleHead from '~/composables/useSimpleHead.ts';

export default defineComponent({
    setup() {
        definePageMeta({
            translatedPaths: (config) => {
                if (!config.links.enabled || !config.links.academicRoute) {
                    return [];
                }
                return [`/${encodeURIComponent(config.links.academicRoute)}`];
            },
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('links.academic.header'),
        }, translator);
    },
});
</script>
