<template>
    <Page>
        <LinksNav />

        <h2>
            <Icon v="user-friends" />
            <T>people.headerLonger</T>
        </h2>

        <section>
            <T>people.intro</T>
        </section>

        <ul class="list-group">
            <li v-for="person in people" class="list-group-item">
                <h3 class="h4">
                    <strong>
                        {{ person.name }}
                    </strong>
                </h3>
                <p>
                    {{ person.description }}
                </p>
                <ul class="mb-2">
                    <li v-for="(pronouns, locale) in person.pronouns">
                        <T>people.languages.{{ locale }}</T><T>quotation.colon</T>
                        <ul class="list-inline d-inline">
                            <li v-for="pronoun in pronouns" class="list-inline-item">
                                <LocaleLink :link="`/${pronoun.link}`" :locale="locale">
                                    <strong>{{ pronoun.display }}</strong>
                                </LocaleLink>
                            </li>
                        </ul>
                    </li>
                </ul>
                <!-- TODO <SourceList v-if="person.sources.length" :names="person.sources"/> -->
            </li>
        </ul>
    </Page>
</template>

<script lang="ts">
import { useFetch, useNuxtApp } from 'nuxt/app';
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import useSimpleHead from '../composables/useSimpleHead.ts';
import { SourceLibrary } from '../src/classes.ts';
import type { SourceRaw } from '../src/classes.ts';
import { people } from '../src/data.ts';

export default defineComponent({
    async setup() {
        definePageMeta({
            translatedPaths: (config) => translatedPathByConfigModule(config.people),
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('people.headerLonger'),
            description: translator.translate('people.description'),
        }, translator);

        const { data: sources } = await useFetch<SourceRaw[]>('/api/sources');

        return {
            config: useConfig(),
            sources: sources.value!,
        };
    },
    data() {
        return {
            people,
        };
    },
    computed: {
        sourceLibrary() {
            return new SourceLibrary(this.config, this.sources);
        },
    },
});
</script>
