import { useSeoMeta } from '@unhead/vue';
import type { UseSeoMetaInput } from '@unhead/vue';
import { useRuntimeConfig } from 'nuxt/app';
import type { RuntimeConfig } from 'nuxt/schema';
import { unref } from 'vue';
import type { MaybeRef } from 'vue';

import { clearLinkedText } from '../src/helpers.ts';
import type { Translator } from '../src/translator';

export const getDefaultSeo = (translator: Translator, runtimeConfig: RuntimeConfig) => ({
    title: translator.translate('title'),
    description: translator.translate('description'),
    keywords: (translator.translate<string[]>('seo.keywords') || []).join(', '),
    banner: `${runtimeConfig.public.baseUrl}/api/banner/zaimki.png`,
});

export interface HeadParams {
    title?: MaybeRef<string | null>;
    description?: MaybeRef<string | null>;
    banner?: MaybeRef<string | null>;
    noindex?: boolean;
    keywords?: MaybeRef<string[]>;
}

const DESCRIPTION_MAX_WORDCOUNT = 24;

export default (
    { title, description, banner, noindex = false, keywords }: HeadParams,
    translator: Translator,
): void => {
    const runtimeConfig = useRuntimeConfig();
    const defaultSeo = getDefaultSeo(translator, runtimeConfig);
    const seo: UseSeoMetaInput = {};

    const seoTitle = () => {
        let titleUnwrapped = unref(title);
        if (!titleUnwrapped) {
            return defaultSeo.title;
        }
        titleUnwrapped = titleUnwrapped.replace(/&#39;/g, '\'');
        titleUnwrapped = titleUnwrapped.replace(/<\/?[^>]+(>|$)/g, ''); // html tags
        titleUnwrapped = clearLinkedText(titleUnwrapped, false);
        titleUnwrapped += ` • ${translator.translate('title')}`;
        return titleUnwrapped;
    };
    seo.title = seoTitle;
    seo.ogTitle = seoTitle;
    seo.twitterTitle = seoTitle;

    const seoDescription = () => {
        let descriptionUnwrapped = unref(description);
        if (!descriptionUnwrapped) {
            return defaultSeo.description;
        }
        descriptionUnwrapped = clearLinkedText(descriptionUnwrapped);
        const words = descriptionUnwrapped.split(' ');
        if (words.length > DESCRIPTION_MAX_WORDCOUNT) {
            descriptionUnwrapped = `${words.slice(0, DESCRIPTION_MAX_WORDCOUNT)
                .join(' ')}…`;
        }
        return descriptionUnwrapped;
    };
    seo.description = seoDescription;
    seo.ogDescription = seoDescription;
    seo.twitterDescription = seoDescription;

    const seoImage = () => {
        let bannerUnwrapped = unref(banner);
        if (!bannerUnwrapped) {
            return defaultSeo.banner;
        }
        bannerUnwrapped = bannerUnwrapped.replace(/^\//, '');
        if (!bannerUnwrapped.startsWith('https://')) {
            bannerUnwrapped = `${runtimeConfig.public.baseUrl}/${bannerUnwrapped}`;
        }
        return bannerUnwrapped;
    };
    seo.ogImage = seoImage;
    seo.twitterImage = seoImage;

    if (noindex) {
        seo.robots = 'noindex';
    }

    seo.keywords = () => {
        const keywordsUnwrapped = unref(keywords) ?? [];
        const mergedKeywords = defaultSeo.keywords.split(', ').concat(keywordsUnwrapped);
        return mergedKeywords.join(', ');
    };

    useSeoMeta(seo);
};
