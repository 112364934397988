export const adsInternal = [
    {
        enabled: false,
        locale: 'en',
        image: 'shop-vt.png',
        placeholders: ['aside-left-middle'],
        link: 'https://shop.pronouns.page',
        display: 'd-none d-md-block',
        alt: 'Pronouns Page merch is here! Get yours at shop.pronouns.page or Etsy!',
    },
    {
        enabled: false,
        locale: 'en',
        image: 'shop-hz.png',
        placeholders: ['aside-right-top'],
        link: 'https://shop.pronouns.page',
        display: 'd-block d-lg-none',
        alt: 'Pronouns Page merch is here! Get yours at shop.pronouns.page or Etsy!',
    },
    {
        enabled: true,
        locale: 'pl',
        image: 'workshops-pl.png',
        placeholders: ['content-0'],
        link: 'https://zaimki.pl/szkolenia',
        display: 'd-none d-md-block',
        alt: 'Oferujemy szkolenia i warsztaty dla firm i organizacji',
    },
    {
        enabled: true,
        locale: 'pl',
        image: 'workshops-pl-mobile.png',
        placeholders: ['content-mobile-0'],
        link: 'https://zaimki.pl/szkolenia',
        display: 'd-block d-md-none',
        alt: 'Oferujemy szkolenia i warsztaty dla firm i organizacji',
    },
    // {
    //     enabled: true,
    //     locale: 'en',
    //     image: 'workshops-en.png',
    //     placeholders: ['content-0'],
    //     link: 'https://en.pronouns.page/workshops',
    //     display: 'd-none d-md-block',
    //     alt: 'We offer training and workshops for companies and organisations.',
    // },
    // {
    //     enabled: true,
    //     locale: 'en',
    //     image: 'workshops-en-mobile.png',
    //     placeholders: ['content-mobile-0'],
    //     link: 'https://en.pronouns.page/workshops',
    //     display: 'd-block d-md-none',
    //     alt: 'We offer training and workshops for companies and organisations.',
    // },
    // {
    //     enabled: true,
    //     locale: null,
    //     image: 'merch-1.png',
    //     placeholders: ['content-0', 'content-mobile-0'],
    //     link: 'https://shop.pronouns.page',
    //     alt: 'Show off your pride and support the project – shop.pronouns.page',
    // },
    // {
    //     enabled: true,
    //     locale: null,
    //     image: 'merch-1.png',
    //     placeholders: ['header'],
    //     link: 'https://shop.pronouns.page',
    //     alt: 'Show off your pride and support the project – shop.pronouns.page',
    //     chance: 0.1,
    // },
];

export default (locale) => {
    const adsInternalPerPlaceholder = {};
    for (const ad of adsInternal) {
        const rightLocale = ad.locale === null || ad.locale === locale;
        if (ad.enabled === false || !rightLocale) {
            continue;
        }

        for (const placeholder of ad.placeholders) {
            if (!adsInternalPerPlaceholder.hasOwnProperty(placeholder)) {
                adsInternalPerPlaceholder[placeholder] = [];
            }
            adsInternalPerPlaceholder[placeholder].push({ chance: 0.25, ...ad });
        }
    }

    return adsInternalPerPlaceholder;
};
