import type { RouteMeta } from 'vue-router';

import type { Config } from '~/locale/config.ts';

export default (config: Config, variants: string[]): Record<string, { paths: string[]; meta: RouteMeta }> => {
    const prefixes = [config.pronouns.prefix || '', ...config.pronouns.sentence?.prefixes || []];
    return Object.fromEntries(variants.map((variant) => {
        return [`pronouns-${variant}`, {
            paths: prefixes.map((prefix) => `${prefix}/${encodeURIComponent(variant)}`),
            meta: { variant },
        }];
    }));
};
