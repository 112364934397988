<script setup lang="ts">
import { useHead, useSeoMeta } from '@unhead/vue';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';

import useConfig from '~/composables/useConfig.ts';
import { getDefaultSeo } from '~/composables/useSimpleHead.ts';

const { $translator: translator } = useNuxtApp();
const runtimeConfig = useRuntimeConfig();
const config = useConfig();

const defaultSeo = getDefaultSeo(translator, runtimeConfig);
const colour = '#C71585';

useHead({
    htmlAttrs: {
        dir: config.dir || 'ltr',
        lang: config.locale,
    },
    link: [
        { rel: 'icon', sizes: '48x48', href: '/favicon.ico' },
        { rel: 'icon', sizes: 'any', type: 'image/svg', href: '/logo/logo-primary.svg' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon-180x180.png' },
    ],
});

useSeoMeta({
    title: defaultSeo.title,
    charset: 'utf-8',
    description: defaultSeo.description,
    keywords: defaultSeo.keywords,
    viewport: 'width=device-width, initial-scale=1',
    appleMobileWebAppTitle: defaultSeo.title,
    themeColor: colour,

    ogType: 'article',
    ogTitle: defaultSeo.title,
    ogDescription: defaultSeo.description,
    ogSiteName: defaultSeo.title,
    ogImage: defaultSeo.banner,

    twitterCard: 'summary_large_image',
    twitterTitle: defaultSeo.title,
    twitterDescription: defaultSeo.description,
    twitterSite: runtimeConfig.public.baseUrl,
    twitterImage: defaultSeo.banner,
});
</script>

<template>
    <NuxtPwaManifest />
    <NuxtLoadingIndicator color="#C71585" error-color="#dc3545" />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>
