<template>
    <div class="modal d-block-force modal-shown" @click="hideClick">
        <div :class="['modal-dialog', 'modal-dialog-centered', 'modal-dialog-scrollable', size ? `modal-${size}` : '']" role="document">
            <div class="modal-content shadow">
                <div v-if="header" class="modal-header">
                    <p class="h5 modal-title">
                        <Icon v-if="icon" :v="icon" />
                        {{ header }}
                    </p>
                </div>
                <div v-if="message" class="modal-body">
                    <p :class="[margin ? 'py-5 text-center' : '']" v-html="message"></p>
                </div>
                <div v-if="editedValue !== undefined" class="modal-body">
                    <ListInput v-if="Array.isArray(value)" v-slot="s" v-model="editedValue">
                        <textarea v-model="s.val" class="form-control" rows="5" @keyup="s.update(s.val)" @update="s.update(s.val)"></textarea>
                    </ListInput>
                    <textarea v-else v-model="editedValue" class="form-control" rows="5"></textarea>
                </div>
                <div v-if="choice" class="modal-footer">
                    <button class="btn btn-outline-dark" @click="cancel">
                        {{ $t('confirm.no') }}
                    </button>
                    <button :class="`btn btn-${colour}`" @click="confirm">
                        {{ $t('confirm.yes') }}
                    </button>
                </div>
                <div v-else-if="value !== undefined" class="modal-footer">
                    <button class="btn btn-outline-dark" @click="cancel">
                        {{ $t('confirm.dismiss') }}
                    </button>
                    <button :class="`btn btn-${colour}`" @click="confirm">
                        {{ $t('confirm.save') }}
                    </button>
                </div>
                <div v-else class="modal-footer">
                    <button :class="`btn btn-${colour}`" @click="confirm">
                        {{ $t('confirm.ok') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import type { Color, ModalSize } from '../src/bootstrap.ts';

export interface DialogueMessage {
    icon?: string;
    header?: string;
    message?: string;
    margin?: boolean;
    size?: ModalSize;
}

export default defineComponent({
    props: {
        choice: { default: false, type: Boolean },
        icon: { default: undefined, type: String },
        header: { default: undefined, type: String },
        message: { default: undefined, type: String },
        margin: { default: true, type: Boolean },
        colour: { default: 'primary', type: String as PropType<Color> },
        value: { default: undefined, type: [String, Array] as PropType<string | string[] | undefined> },
        size: { default: undefined, type: String as PropType<ModalSize | undefined> },
    },
    emits: ['confirm', 'cancel'],
    data() {
        return {
            editedValue: this.value,
        };
    },
    mounted() {
        window.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                e.stopPropagation();
                e.preventDefault();
                this.cancel();
            } else if (e.keyCode === 13) {
                e.stopPropagation();
                e.preventDefault();
                this.confirm();
            }
        });
    },
    methods: {
        confirm() {
            this.$emit('confirm', this.editedValue);
        },
        cancel() {
            this.$emit('cancel');
        },
        hideClick(event: Event) {
            if (event.target === this.$el) {
                this.cancel();
            }
        },
    },
});
</script>
