<template>
    <Page v-if="config.workshops?.enabled">
        <h2>
            <Icon v="chalkboard-teacher" />
            <T>workshops.headerLong</T>
        </h2>

        <section>
            <div class="text-center mb-3 mx-3 float-md-end" style="max-width: 480px;">
                <img :src="`/banners/workshops-${config.locale}-full.png`" class="mw-100" alt="">
            </div>

            <T>workshops.content</T>

            <a :href="`mailto:${config.workshops.email}`" target="_blank" class="btn btn-primary w-100">
                <Icon v="envelope" />
                {{ config.workshops.email }}
            </a>
        </section>

        <Separator icon="heart" />

        <Mission />
    </Page>
</template>

<script lang="ts">
import { useNuxtApp } from 'nuxt/app';
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import useSimpleHead from '../composables/useSimpleHead.ts';

export default defineComponent({
    setup() {
        definePageMeta({
            translatedPaths: (config) => translatedPathByConfigModule(config.workshops),
        });

        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('workshops.headerLong'),
        }, translator);
        return {
            config: useConfig(),
        };
    },
});
</script>
