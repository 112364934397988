import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin, useRouter } from 'nuxt/app';

const USER_AT = /^\/@.+/;
const USER_SUBPAGE = /^\/(u|card)\/.*/;

export const normalizeUrl = (page: URL): URL => {
    if (USER_AT.test(page.pathname)) {
        page.pathname = page.pathname.replace(USER_AT, '/@--redacted--');
    }
    if (USER_SUBPAGE.test(page.pathname)) {
        page.pathname = page.pathname.replace(USER_SUBPAGE, '/$1/--redacted--');
    }
    page.search = '';
    page.hash = '';
    return page;
};

export default defineNuxtPlugin(() => {
    const router = useRouter();
    router.afterEach((to, from) => {
        try {
            const toUrl = normalizeUrl(new URL(to.fullPath, window.location.href));
            const fromUrl = normalizeUrl(new URL(from.fullPath, window.location.href));
            if (toUrl.toString() === fromUrl.toString()) {
                // same page, but different search param
                return;
            }
            console.debug('[analytics] tracking page view:', toUrl.toString());
            useTrackPageview({
                data: {
                    url: toUrl.toString(),
                },
            });
        } catch (error) {
            Sentry.captureException(error);
        }
    });
});
