import { default as abuseReports0aceFmvjBCMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93gZlwmVVF5yMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index8HCWes8LilMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/index.vue?macro=true";
import { default as moderationBTpvEu8D9DMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/moderation.vue?macro=true";
import { default as pendingBansjb9S3kSvRuMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/pendingBans.vue?macro=true";
import { default as profilespONhyZdDRwMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/profiles.vue?macro=true";
import { default as expensesJPVKtV0CwqMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexCEkdOA9In3Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewjZgFeEPtvaMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingPcs4wuc808Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingY3k25EZqizMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/translations/missing.vue?macro=true";
import { default as usersRKOmXcDHOdMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/users.vue?macro=true";
import { default as apiIBriSucYNlMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/api.vue?macro=true";
import { default as _91slug_93aOnrx9EzRZMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/blog/[slug].vue?macro=true";
import { default as indexfndVH4aCj3Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93LTzzPiINfHMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93YrJ8E41sHoMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminCA4XZQCTxRMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/census/admin.vue?macro=true";
import { default as indexTVDBCpzFPfMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/census/index.vue?macro=true";
import { default as contactEn1gqq38A2Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/contact.vue?macro=true";
import { default as designNrcgzTaNQsMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/design.vue?macro=true";
import { default as englishkEh0O13bk0Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/english.vue?macro=true";
import { default as faqndVvlQgxbXMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/faq.vue?macro=true";
import { default as inclusiveZzp5eRam6GMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/inclusive.vue?macro=true";
import { default as index3dyIeCxsT0Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/index.vue?macro=true";
import { default as license97ephq5fkqMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/license.vue?macro=true";
import { default as academic4dNwvGiGXwMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/academic.vue?macro=true";
import { default as indexJBszTIfBh5Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/index.vue?macro=true";
import { default as media1s1b0h97bUMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/media.vue?macro=true";
import { default as translinguisticsupQJoRKbmgMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/translinguistics.vue?macro=true";
import { default as zinezmJikSAoHUMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/zine.vue?macro=true";
import { default as namesrCymO3dI6mMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/names.vue?macro=true";
import { default as indexImO35TcSgWMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/nouns/index.vue?macro=true";
import { default as templatesvlRBT62WeLMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/nouns/templates.vue?macro=true";
import { default as peoplerhEuHkRPIeMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/people.vue?macro=true";
import { default as privacyXHbFF74siFMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/privacy.vue?macro=true";
import { default as _91username_93MkZ5AKhmBZMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93O9qQTH008YMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/profile/card-[username].vue?macro=true";
import { default as editorrQFffBkJUxMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/profile/editor.vue?macro=true";
import { default as anyJL0WKOjZwOMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/any.vue?macro=true";
import { default as asksVbLZXLIzSMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/ask.vue?macro=true";
import { default as avoidingew8BHIrRiVMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/avoiding.vue?macro=true";
import { default as indexAJmm5zw3xYMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/index.vue?macro=true";
import { default as mirrorPxpO92zBUbMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/mirror.vue?macro=true";
import { default as pronoun8sZnKMAiViMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesTBpKUre2C2Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/sources.vue?macro=true";
import { default as teamdqWogO5kupMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/team.vue?macro=true";
import { default as terminologycolT3grAYWMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/terminology.vue?macro=true";
import { default as terms7yUeorFit6Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/terms.vue?macro=true";
import { default as useruneGcwi4M2Meta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/user.vue?macro=true";
import { default as workshopszWkvJ3pq5dMeta } from "/home/admin/www/en.pronouns.page/release/20241201121651/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports0aceFmvjBCMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansjb9S3kSvRuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiIBriSucYNlMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93aOnrx9EzRZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexfndVH4aCj3Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93LTzzPiINfHMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93YrJ8E41sHoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminCA4XZQCTxRMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexTVDBCpzFPfMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactEn1gqq38A2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishkEh0O13bk0Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqndVvlQgxbXMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveZzp5eRam6GMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academic4dNwvGiGXwMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexJBszTIfBh5Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media1s1b0h97bUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsupQJoRKbmgMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinezmJikSAoHUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesrCymO3dI6mMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexImO35TcSgWMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesvlRBT62WeLMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplerhEuHkRPIeMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyXHbFF74siFMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93MkZ5AKhmBZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93O9qQTH008YMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorrQFffBkJUxMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyJL0WKOjZwOMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: asksVbLZXLIzSMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingew8BHIrRiVMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexAJmm5zw3xYMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorPxpO92zBUbMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoun8sZnKMAiViMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesTBpKUre2C2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamdqWogO5kupMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologycolT3grAYWMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms7yUeorFit6Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: useruneGcwi4M2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopszWkvJ3pq5dMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241201121651/pages/workshops.vue")
  }
]